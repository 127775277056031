<template>
  <swiper :autoplay="swiperopt.autoplay"
          :loop="swiperopt.loop"
          :speed="swiperopt.speed"
          :pagination="swiperopt.pagination"
          class="swiper-073101">

    <swiper-slide v-for="(item, index) in swiperImgs"
                  :key="index">
      <img class="swiper-slide-img"
           :style="{height: height}"
           :src="item">
    </swiper-slide>
  </swiper>
</template>
<script>
import { reactive } from 'vue'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Autoplay, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
    }
  },
  props: ['height', 'swiperImgs'],
  setup () {
    const swiperopt = reactive({
      autoplay: {
        deplay: 3000,
        disableOnInteraction: false
      },
      loop: true,
      speed: 3000,
      pagination: {
        clickable: true
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    })
    return {
      swiperopt
    }
  }
}
</script>
<style scoped lang="less">
.swiper-073101 {
  width: 100%;
  img {
    width: 100%;

    @media screen and (max-width: 800px) {
      height: 250px !important;
    }
  }
}

@media screen and (max-width: 414px) {
  .swiper-slide-img {
    height: 150px !important;
  }
}
</style>
