<template>
  <div class="out-073003">
    <a>
      <span class="email">
      </span>
      <span class="text">19626753@qq.com</span>
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073003 {
  display: inline-block;
  margin-left: 8px;
  .email {
    display: inline-block;
    width: 18px;
    height: 16px;
    background: url("../../assets/images/sprite.png")
      /*tpa=http://www.szfangwei.cn/Public/Web/images/sprite.png*/ no-repeat -20px
      0;
    vertical-align: bottom;
    margin-right: 4px;
  }
  .text {
    text-align: right;
    color: #888888;
    font-size: 12px;
    font-family: "微软雅黑", Arial;
  }
}
</style>
