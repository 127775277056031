<template>
  <div class="out-073120">
    <span id="a">我们是</span>
    <span id="b"></span>
    <span id="c">谁</span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073120 {
  margin-top: 50px;
  #a {
    font-size: 23px;
    color: black;
    font-family: "微软雅黑";
    margin-right: 8px;
  }
  #b {
    font-size: 23px;
    color: #333;
    font-family: "monospace", "Courier New";
    margin-right: 8px;
  }
  #c {
    font-size: 35px;
    color: rgb(12, 165, 170);
    font-family: "monospace", "Courier New";
    margin-right: 8px;
    transition: all 1s;
    display: inline-block;
    &:hover {
      transform: rotate(360deg);
    }
  }
}
</style>
