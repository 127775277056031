<template>
  <div class="out-080209 clearfix">
    <div class="logo">
      <logo></logo>
    </div>
    <div class="right">
      <img-text-top-01></img-text-top-01>
      <nav-01></nav-01>
    </div>
  </div>
</template>

<script>
import ImgTextTop01 from './Img-text-top-01.vue'
import Logo from './logo.vue'
import Nav01 from './Nav-01.vue'
export default {
  components: { Nav01, Logo, ImgTextTop01 }
}
</script>

<style scoped lang="less">
.out-080209 {
  width: 1200px;
  height: 100px;
  margin: 10px auto;
  .logo {
    float: left;
    margin-top: 10px;
  }
  .right {
    float: right;
  }
}
</style>
