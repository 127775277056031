<template>
  <div class="out-073120">
    <span id="a">服务范围</span>
    <span id="b">SERVICE</span>
    <span id="c">SCOPE</span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073120 {
  margin: 30px auto;
  min-width: 1200px;
  #a {
    font-size: 23px;
    color: black;
    font-family: "微软雅黑";
    margin-right: 8px;
  }
  #b {
    font-size: 23px;
    color: #333;
    font-family: "monospace", "Courier New";
    margin-right: 8px;
  }
  #c {
    font-size: 23px;
    color: rgb(12, 165, 170);
    font-family: "monospace", "Courier New";
    margin-right: 8px;
  }
}
</style>
