<template>
  <div class="body">
    <div class="home-content">
      <my-swiper01 :swiperImgs="swiperImgs"
                   :height="swiperHeight"></my-swiper01>
    </div>
    <title-01></title-01>
    <line-01></line-01>
    <text-line-center-01></text-line-center-01>
    <list-cards-01></list-cards-01>
    <text-line-center-02></text-line-center-02>
    <text-line-center-03></text-line-center-03>
    <grid-01></grid-01>
    <btn-cases-more-01></btn-cases-more-01>
    <list-cards-02></list-cards-02>
    <table-cards-01></table-cards-01>
  </div>
</template>

<script>
import MySwiper01 from './webComponents/MySwiper01.vue'
import Title01 from './webComponents/Title-01.vue'
import Line01 from './webComponents/Line-01.vue'
import TextLineCenter01 from './webComponents/Text-line-center-01.vue'
import ListCards01 from './webComponents/List-cards-01.vue'
import TextLineCenter02 from './webComponents/Text-line-center-02.vue'
import TextLineCenter03 from './webComponents/Text-line-center-03.vue'
import Grid01 from './webComponents/Grid-01.vue'
import BtnCasesMore01 from './webComponents/Btn-cases-more-01.vue'
import ListCards02 from './webComponents/List-cards-02.vue'
import TableCards01 from './webComponents/Table-cards-01.vue'

export default {
  components: {
    MySwiper01,
    Title01,
    Line01,
    TextLineCenter01,
    ListCards01,
    TextLineCenter02,
    TextLineCenter03,
    Grid01,
    BtnCasesMore01,
    ListCards02,
    TableCards01
  }, // MySwiper,
  data: function () {
    return {
      swiperImgs: [
        require('../assets/images/banner.jpg'),
        require('../assets/images/banner2.jpg'),
        require('../assets/images/1453974599.jpg')
      ],
      swiperHeight: '560px'
    }
  }
}

</script>

<style scoped lang="less">
.home-content {
  width: 100%;
  min-width: 1200px;
}
.out-333 {
  width: 100%;
  overflow: hidden;
}
</style>
