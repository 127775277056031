<template>
  <xfz-header></xfz-header>
  <xfz-solution-body></xfz-solution-body>
  <xfz-footer></xfz-footer>
</template>

<script>
import XfzFooter from '../components/Xfz-footer.vue'
import XfzHeader from '../components/Xfz-header.vue'
import XfzSolutionBody from '../components/Xfz-solution-body.vue'
export default {
  components: { XfzSolutionBody, XfzHeader, XfzFooter }
}
</script>

<style>
</style>
