<template>
  <footer class="footer-080205">
    <div class="contact">
      <div class="wrap clearfix">
        <div class="home_about fl">
          <dl>
            <dt>
              <a @click.prevent="jump('/about')"
                 href="#">
                关于我们
              </a>
            </dt>
            <dd>
              噫吖网专注于网站定制，始终追求“用最快的速度定制出最好的网站”。懂您所需、做您所想！我们一直在思考如何为客户创造更大的价值，让客户更省心!
            </dd>
            <dd>
              <a class="more"
                 @click.prevent="jump('/about')"
                 href="#">
                查看更多 >>
              </a>
            </dd>
          </dl>
        </div>
        <div class="home_case fl">
          <h2>
            <a>
              最新案例
            </a>
          </h2>
          <ul>
            <li>
              <h5>
                <a>
                  日通国际物流（深圳...
                </a>
              </h5>
              <p class="text">
                <a>
                  日通国际物流（深圳）有限公司是...
                </a>
              </p>
            </li>
            <li>
              <h5>
                <a>
                  小牛资本
                </a>
              </h5>
              <p class="text">
                <a href="script: this.">
                  小牛资本管理集团以互联网金...
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div class="home_service fl">
          <h2>
            <a href="#"
               tppabs="#">
              服务范围
            </a>
          </h2>
          <a class="sub"
             @click.prevent="jump('/cases')"
             href="#">
            品牌官网设计
          </a>
          <a class="sub"
             href="#"
             @click.prevent="jump('/cases')">
            商城网站建设
          </a>
          <a class="sub"
             href="#"
             @click.prevent="jump('/cases')">
            手机网站建设
          </a>
          <a class="sub"
             href="#"
             @click.prevent="jump('/cases')">
            微信网站建设
          </a>
          <a class="sub"
             href="#"
             @click.prevent="jump('/cases')">
            其他网站定制
          </a>
        </div>
        <div class="home_contact fl">
          <h2>
            <a href="#"
               @click.prevent="jump('/contect')">
              联系我们
            </a>
          </h2>
          <ul>
            <li>
            </li>
            <li>
              <i class="weibo">
              </i>
              Q Q：
              <a>
                19626753
              </a>
            </li>
            <li>
              <i class="email">
              </i>
              邮 箱：
              <a>
                19626753@qq.com
              </a>
            </li>
            <li>
              <i class="call">
              </i>
              电 话：13510798915
            </li>
            <li>
              <i class="weixin">
              </i>
              <a>
                付款方式
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="blogroll">
      <div class="wrap">
        友情链接：
        <a>
          网站建设公司
        </a>
        &nbsp;
        <p class="copyright">
          <a href="https://beian.miit.gov.cn">粤ICP备20062427号-2</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    jump (path) {
      this.$router.push(path)
      console.log(path)
    }
  }

}
</script>

<style scoped lang="less">
.footer-080205 {
  width: 100%;
  .contact {
    background: #2eafbb;
    margin-top: 40px;
    padding-top: 38px;
    padding-bottom: 60px;
    .wrap {
      width: 1200px;
      margin: 0 auto;
      .home_about {
        float: left;
        width: 250px;
        dt {
          margin-bottom: 24px;
          text-align: left;
          a {
            font-size: 18px;
            color: #fff;
            font-weight: normal;
          }
        }
        dd {
          text-align: left;
          color: #eeeeee;
          line-height: 28px;
          font-family: "宋体";
          font-size: 12px;
        }
        .more {
          color: #fff;
          display: inline-block;
          border: 1px solid #cccccc;
          line-height: 38px;
          padding: 0 24px;
          margin-top: 12px;
        }
        &:hover .more {
          border-color: #fff;
        }
      }
      .home_case {
        float: left;
        width: 300px;
        margin-left: 45px;
        h2 {
          margin-bottom: 28px;
          text-align: left;
          a {
            font-size: 18px;
            color: #fff;
            font-weight: normal;
          }
        }
        li {
          border-top: 1px solid #ccc;
          height: 52px;
          padding: 14px 0;
          &:first-child {
            border: 0;
            padding-top: 3px;
          }
          img {
            float: left;
            margin-right: 18px;
          }
          h5 {
            margin-top: -5px;
            line-height: 28px;
            text-align: left;
            a {
              font-size: 14px;
              color: #fff;
              font-weight: normal;
            }
          }
        }
        .text {
          line-height: 22px;
          text-align: left;
          a {
            color: #dddddd;
            font-family: "宋体";
            font-size: 12px;
          }
        }
        .label {
          color: #cccccc;
          line-height: 28px;
        }
      }
      .home_service {
        width: 230px;
        margin-left: 45px;
        float: left;
        h2 {
          margin-bottom: 28px;
          text-align: left;
          a {
            font-size: 18px;
            color: #fff;
            font-weight: normal;
          }
        }
        a.sub {
          font-size: 12px;
          color: #fff;
          line-height: 38px;
          width: 98px;
          border: 1px solid #cccccc;
          text-align: center;
          margin-right: 12px;
          margin-bottom: 12px;
          margin-top: 3px;
          float: left;
          &:hover {
            border-color: #fff;
          }
        }
      }
      .home_contact {
        margin-left: 40px;
        float: left;
        h2 {
          margin-bottom: 28px;
          text-align: left;
          a {
            font-size: 18px;
            color: #fff;
            font-weight: normal;
          }
        }
        li {
          color: #ddd;
          margin-bottom: 14px;
          text-align: left;
          font-size: 12px;
          i {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
            vertical-align: middle;
          }
          .addres {
            background: url("../../assets/images/sprite.png") no-repeat 0 -20px;
          }
          .weibo {
            background: url("../../assets/images/sprite.png") no-repeat -20px -20px;
          }
          .email {
            background: url("../../assets/images/sprite.png") no-repeat -40px -20px;
          }
          .weixin {
            background: url("../../assets/images/sprite.png") no-repeat -80px -20px;
          }
          .call {
            background: url("../../assets/images/sprite.png") no-repeat -0px -0px;
          }
          a {
            color: #ddd;
          }
        }
      }
    }
  }
  .blogroll {
    line-height: 28px;
    color: #999;
    text-align: center;
    padding: 30px 0;
    .wrap {
      width: 1200px;
      margin: 0 auto;
      font-size: 12px;
      a {
        color: #999;
        display: inline-block;
        &:hover {
          color: #333;
        }
      }
    }
    .copyright {
      margin-top: 16px;
    }
  }
}
</style>
