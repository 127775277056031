<template>
  <xfz-header></xfz-header>
  <xfz-mobile-body></xfz-mobile-body>
  <xfz-footer></xfz-footer>
</template>

<script>
import XfzFooter from '../components/Xfz-footer.vue'
import XfzHeader from '../components/Xfz-header.vue'
import XfzMobileBody from '../components/Xfz-mobile-body.vue'
export default {
  components: { XfzMobileBody, XfzHeader, XfzFooter }
}
</script>

<style>
</style>
