<template>
  <div class="logo_out">
    <h1 class="logo_img">
      <a href="index.html"
         tppabs="http://www.tisx.net/"
         class="logo">
        <img src="../../assets/yiiiyaaalogo.png"
             alt="TI Alliance"
             width="154"
             height="49" />
      </a>
    </h1>
    <div class="logo_text">
      <img src="../../assets/images/logo_text.jpg"
           alt="懂你所需，做你所想"
           width="163"
           height="49" />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.logo_out {
  .logo_img {
    float: left;
    a {
      padding-top: 24px;
      img {
        margin-top: 15px;
        border: 0;
        vertical-align: middle;
      }
    }
  }
  .logo_text {
    float: left;
    width: 163px;
    margin-top: 24px;
    padding-left: 5px;
  }
}
</style>
