<template>
  <div class="out-080405">
    <ul class="clearfix">
      <li v-for="(item,index) in list"
          :key="index">
        <div class="pic">
          <img :src="item.src"
               alt="">
        </div>
        <h2 class="title">{{item.title}}</h2>
        <div class="line"></div>
        <p class="content">{{item.content}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import img01 from '../../assets/images/1430299187.jpg'
import img02 from '../../assets/images/1430299202.jpg'
import img03 from '../../assets/images/1430299219.jpg'
import img04 from '../../assets/images/1430299249.jpg'
import img05 from '../../assets/images/1430299230.jpg'
import img06 from '../../assets/images/1430299264.jpg'
import img07 from '../../assets/images/1430299292.jpg'
import img08 from '../../assets/images/1430299302.jpg'
import img09 from '../../assets/images/1430299315.jpg'
export default {
  data () {
    return {
      list: [
        {
          src: img01,
          title: '企业网站解决方案',
          content: '企业网站作为一个公司的网络名片，最主要的作用是展示公司形象和宣传公司的服务或产品，所以做一个企业网站，关键...'
        },
        {
          src: img02,
          title: '旅游网站解决方案',
          content: '随着互联网的迅速，越多越多人，尤其是上班白领，都喜欢在网上查看旅游相关信息，然后再网上直接预定购买旅游行程。...'
        },
        {
          src: img03,
          title: '手机网站解决方案',
          content: '随着智能手机的迅速发展，移动互联网时代已经到来，这带给我们是一个机遇还是危机？全取决于...'
        },
        {
          src: img04,
          title: '商城网站解决方案',
          content: '商城网站需要强大的后台管理系统、良好的用户购物体验、安全稳定的服务器空间，商城网站需要强大的后台管理系...'
        },
        {
          src: img05,
          title: '外贸网站解决方案',
          content: '外贸网站的面向用户主要为国外客户，所以除了文字采用英文外，风格设计、页面编码、操作习惯都要适应国外客户需...'
        },
        {
          src: img06,
          title: '服装网站解决方案',
          content: '风格上清新明快，符合最新审美观，制作精细，有积极开放的感觉；使用流行的大区块划分概念，配合使用大广告条，突出宣传...'
        }, {
          src: img07,
          title: '集团上市公司解决方案',
          content: '随着集团上市公司的不断壮大，集团企业、上市公司的信息需要采用各种途径公众于媒体、上级领导、内部员工、投...'
        },
        {
          src: img08,
          title: '酒店网站解决方案',
          content: '在建设酒店网站时我们要充分考虑“酒店”的经营服务特色，因此我们在制作方案时会注重系统的实用性、可靠性、...'
        },
        {
          src: img09,
          title: '门户网站解决方案',
          content: '门户网站不是简单的内容管理，而是新闻内容的聚合，做好门户网站，需要管理好网站的内容，同时...'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.out-080405 {
  width: 100%;
  margin-top: 50px;
  ul {
    width: 1200px;
    margin: 0px auto;
    li {
      float: left;
      width: 370px;
      margin: 0 15px;
      height: 330px;
      .pic {
        margin: 0 auto;
        overflow: hidden;
        img {
          width: 370px;
          height: 180px;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
      .title {
        text-align: center;
        margin: 15px auto;
        font-size: 18px;
        font-family: "微软雅黑";
      }
      .line {
        height: 1px;
        background-color: rgb(11, 140, 150);
        width: 100%;
      }
      .content {
        text-align: left;
        font-size: 14px;
        margin: 10px 20px;
        line-height: 20px;
      }
    }
  }
}
</style>
