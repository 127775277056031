<template>
  <div class="out-080177 clearfix">
    <div class="home_solutions_list wrap">
      <div class="home_solutions_list_inner clearfix">
        <dl class="solu_dl_0"
            v-for="item in list"
            :key="item.id">
          <dt>
            <a>
            </a>
          </dt>
          <dd class="t">
            <a>
              {{item.title}}
            </a>
          </dd>
          <dd class="spec">
            {{item.content}}
          </dd>
          <dd class="bg">
          </dd>
        </dl>
        <div class="c">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import img01 from '../../assets/images/index201601/solutions_bg.jpg'
export default {
  data () {
    return {
      list: [
        {
          title: '企业网站解决方案',
          content: '企业网站作为一个公司的网络名片，最主要的作用是展示公司形象和宣传公司的服务或产品，所...'
        },
        {
          title: '旅游网站解决方案',
          content: '随着互联网的迅速，越多越多人，尤其是上班白领，都喜欢在网上查看旅游相关信息，然后再网上直...'
        },
        {
          title: '手机网站解决方案',
          content: '随着智能手机的迅速发展，移动互联网时代已经到来，这带给我们是一个机遇还是危机？全取决于...'
        },
        {
          title: '商城网站解决方案',
          content: '商城网站需要强大的后台管理系统、良好的用户购物体验、安全稳定的服务器空间，商城网站...'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.out-080177 {
  background: url("../../assets/images/index201601/solutions_bg.jpg") no-repeat
    top center;
  width: 100%;
  min-width: 1200px;
  height: 450px;
  .home_solutions_list {
    width: 1200px;
    margin: 30px auto;
    overflow: hidden;
    .home_solutions_list_inner {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      dl {
        float: left;
        width: 200px;
        // margin-right: 63px;
        padding: 25px;
        text-align: center;
        padding-top: 54px;
        padding-bottom: 35px;
        position: relative;
        z-index: 3;
        dt {
          z-index: 1;
          position: relative;
          margin-left: 32px;
          a {
            display: block;
            width: 140px;
            height: 140px;
            background: url("../../assets/images/index201601/s_1.png") no-repeat
              0 0;
            transition: all 0.3s;
          }
        }
        .t {
          line-height: 30px;
          height: 30px;
          margin: 16px 0px;
          z-index: 1;
          position: relative;
          a {
            color: #000000;
            font-size: 18px;
            transition-delay: 0.2s;
          }
        }
        .spec {
          color: #666666;
          font-size: 12px;
          font-family: "宋体";
          line-height: 24px;
          width: 204px;
          z-index: 1;
          position: relative;
          transition-delay: 0.2s;
        }
        .bg {
          background-color: #2eafbb;
          position: absolute;
          z-index: 0;
          left: 0;
          bottom: 0;
          height: 0px;
          width: 100%;
          transition: all 0.5s !important;
        }

        &:hover .bg {
          height: 370px;
        }
        &:hover .t a {
          color: #fff;
        }
        &:hover .spec {
          color: #fff;
        }
        &:hover dt a {
          background: url("../../assets/images/index201601/s_1.png") no-repeat 0 -140px;
        }
      }
    }
  }
}
</style>
