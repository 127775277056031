<template>
  <div class="culture-080301 clearfix">
    <div class="about_title">
      <img src="../../assets/images/a05.png"
           alt="方维文化"
           width="182"
           height="52">
    </div>
    <div class="wrap clearfix">
      <div class="about_main"
           style="left: 0px; top: 0px;">
        <div class="about_cont">
          <h2>
            先进的技术+远瞻的眼光
          </h2>
          <h4>
            互联网行业瞬息万变，谁能洞察未来，谁就能引领行业发展。
          </h4>
          <p class="text">
            噫吖团队拥有一支朝气蓬勃的团队，我们执着、敏锐、追求更好；通过自己的专业眼光，为客户提供各类解决方案，始终为客户思考如何占领互联网。 一个人能走多远，取决于与谁同行，TI团队是一个富有理想和激情的团队，也是一个技术专业化、管理人性化、创新性和学习型的优秀团队。
          </p>
        </div>
      </div>
      <div class="about_pic"
           style="left: 0px; top: 0px;">
        <img src="../../assets/images/a07.png"
             alt="方维价值观"
             width="543"
             height="307"
             style="transform: rotateX(-360deg); transition: all 0.6s ease-in-out 0s;">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.culture-080301 {
  width: 100%;
  background: url("../../assets/images/a04.jpg") no-repeat center;
  min-height: 540px;
  .about_title {
    margin: 30px auto;
  }
  .wrap {
    width: 1200px;
    margin: 35px auto;
    .about_cont {
      float: left;
      width: 550px;
      margin-top: 25px;
      h2 {
        text-align: left;
        font-size: 26px;
        color: #333;
        font-weight: normal;
        margin-bottom: 5px;
      }
      h4 {
        margin-top: 30px;
        text-align: left;
        font-size: 16px;
        color: #333;
        font-weight: normal;
        background: url("../../assets/images/a03.jpg") no-repeat left bottom;
        padding-bottom: 8px;
        margin-bottom: 30px;
        white-space: nowrap;
      }
      p {
        text-align: left;
      }
      .text {
        font-size: 14px;
        color: #333;
        line-height: 28px;
      }
      li {
        margin-bottom: 14px;
        width: 300px;
        float: left;
        span {
          background: #4bcdd9;
          color: #fff;
          font-size: 10px;
          padding: 0 4px;
          margin-right: 8px;
          line-height: 12px;
          *padding: 2px 4px;
        }
      }
    }
    .about_pic {
      margin-top: 25px;
      float: right;
    }
  }
}
</style>
