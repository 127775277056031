<template>
  <div class="out-080233">
    <header-01></header-01>
  </div>
</template>

<script>
import Header01 from './webComponents/Header-01.vue'

export default {
  components: { Header01 }
}
</script>

<style scoped lang="less">
.out-080233 {
  width: 100%;
}
</style>
