<template>
  <div class="out-080421">
    <div class="wrap">
      <div class="news-card clearfix"
           v-for="(item,index) in list"
           :key="index">
        <div class="left">
          <p class="data">{{item.data}}</p>
          <p class="week">{{item.week}}</p>
        </div>
        <div class="right">
          <h2>{{item.title}}</h2>
          <p>{{item.content}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          title: '可定制云商城网站建设解决方案（3）',
          content: '承诺支持时间内，45分钟响应，根据故障分级不同在相应时间内解决问题； 1） 如软件系统无法运行，用户无法使用。我们会在24小时内提供解决方案或替代方法。 2）如软件系统操作性能严重降级，用户使用有困难。我们会在48小时内提供解决方案或替代方法。 3）如软件系统性能受损或存在轻微错误，用户使用有轻微影响。我们将在72小时内提供解决方案或替代方法。 ',
          data: '2021/5/13',
          week: '星期四'
        },
        {
          title: '噫吖网站设计拥有什么优势',
          content: '互联网时代很多企业都开始关注网站的使用和价值，因为网站可以将自己的商品和内容进行更好的展示宣传效果，开辟全新的运营模式，最重要的是价格还是非常便宜的，所以不少商家对于网站都是非常看中的。但是在这过程当中，网站设计就成为了非常需要考虑的关键问题，如果一个网站设计的不好，那么就根本无法实现网站的实际价值，在这样的问题上，只能通过选择更好的网站设计来进行弥补。 网站设计... ',
          data: '2021/5/13',
          week: '星期四'
        },
        {
          title: '噫吖网站设计拥有什么优势',
          content: '互联网时代很多企业都开始关注网站的使用和价值，因为网站可以将自己的商品和内容进行更好的展示宣传效果，开辟全新的运营模式，最重要的是价格还是非常便宜的，所以不少商家对于网站都是非常看中的。但是在这过程当中，网站设计就成为了非常需要考虑的关键问题，如果一个网站设计的不好，那么就根本无法实现网站的实际价值，在这样的问题上，只能通过选择更好的网站设计来进行弥补。 网站设计... ',
          data: '2021/5/13',
          week: '星期四'
        },
        {
          title: '响应式网站之解决响应式图片尺寸大小问题',
          content: '前言 随着网站开发技术的发展，浏览器的升级，操作系统的升级，HTML5技术的推出，对HTML5的支持变得越来越全面，也让旧浏览器的占有率越来越低，同时不同尺寸的显示终端越来越多，这些让我们看到了响应式网站普及的署光及迫切需求。但，今天要谈的是响应式网站一个必须要解决的问题：图片响应式，一张大图如果PC端是这么大、平板端是这么大、手机端还是这么大的话，实在太不科学了，一是手机流量占... ',
          data: '2021/5/13',
          week: '星期四'
        },
        {
          title: '当搜索引擎越来越挑剔网站收录越来越难时我们能做什么',
          content: '近来小编发觉写的很多文章都不收录了，看看后台统计数据就知道，如下截图： 我感到非常纳闷，以为是被惩罚了，不过排名没有多大变化，于是尝试着写更加丰富的内容，发觉还是会收录，于是我就摸索出如下的规则供大家参考： 1、图片太多，完全没有文字也不收录； 2、文字多一般能收录，最好在1200字以上，当然不是在网上随便抄够1200字就行了，至少也应该是伪原创（用自己的话重新表达）。 3、文章排版要容...',
          data: '2021/5/13',
          week: '星期四'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.out-080421 {
  width: 100%;
  .wrap {
    width: 1200px;
    margin: 20px auto;
    .news-card {
      width: 1200px;
      height: 130px;
      margin: 15px;
      .left {
        float: left;
        background-color: aquamarine;
        text-align: center;
        width: 20%;
        height: 100%;
        .data {
          margin: 20px auto;
          font-size: 30px;
          font-weight: 500;
          color: aliceblue;
        }
        .week {
          font-size: 20px;
          font-weight: 300;
          color: aliceblue;
        }
      }
      .right {
        width: 80%;
        height: 100%;
        background-color: #eee;
        text-align: center;
        float: left;
        h2 {
          text-align: center;
          font-size: 20px;
          margin: 10px auto;
        }
        p {
          text-align: left;
          font-size: 14px;
          width: 90%;
          height: 60px;
          margin: 0 auto;
          padding: 5px 20px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
