<template>
  <xfz-header></xfz-header>
  <xfz-body></xfz-body>
  <xfz-footer></xfz-footer>
</template>

<script>
import XfzBody from '../components/Xfz-body.vue'
import XfzFooter from '../components/Xfz-footer.vue'
import XfzHeader from '../components/Xfz-header.vue'
export default {
  components: { XfzHeader, XfzBody, XfzFooter }
}
</script>

<style>
</style>
