<template>
  <div class="out-080419">
    <div class="banner">
      <img src="../assets/images/cn02.png"
           alt="">
    </div>
    <ul class="clearfix">
      <li v-for="(item,index) in list"
          :key="index"
          :style="item.color">
        <i style="background: url(../assets/images/sprite2.png) no-repeat 0 0;"></i>
        <h2>{{item.title}}</h2>
        <p>{{item.content}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          title: '公司地址',
          content: '深圳市龙岗区坂田街道坂雪岗大道3010号山水时尚酒店2楼225',
          color: 'background-color: rgb(30, 241, 241);'
        },
        {
          title: '联系方式',
          content: '联系电话：13510798915',
          color: 'background-color: rgb(30, 157, 241);'
        },
        {
          title: '电子邮箱',
          content: '19626753@qq.com',
          color: 'background-color: rgb(241, 30, 100);'
        },
        {
          title: '微信',
          content: '13510798915',
          color: 'background-color: rgb(241, 227, 30);'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.out-080419 {
  width: 100%;
  min-width: 1200px;
  margin-bottom: 80px;
  .banner {
    width: 1200px;
    margin: 20px auto;
    img {
      margin: 20px auto;
      width: 200px;
      height: 53px;
    }
  }
  ul {
    width: 1200px;
    margin: 10px auto;
    li {
      float: left;
      width: 270px;
      height: 200px;
      margin: 0 15px;
      background-color: #eee;
      transition: all 0.1s;
      i {
        width: 36px;
        height: 46px;
        margin: 20px auto;
      }
      h2 {
        margin: 50px auto;
        font-size: 16px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        text-align: center;
        padding: 0px 20px;
        line-height: 28px;
      }
      &:hover {
        box-shadow: 2px 2px 3px #333;
        display: relative;
        top: -3px;
        left: -3px;
      }
    }
  }
}
</style>
