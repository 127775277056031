<template>
  <div class="out-073181">
    一个人能走多远，取决于与谁同行，TI团队是一个富有理想和激情的团队，是一个蓬勃向上并富有朝气的团队，
    <br />
    也是一个技术专业化、管理人性化、创新性和学习型的优秀团队。
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073181 {
  width: 1200px;
  color: #222222;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  margin: 50px auto;
}
</style>
