<template>
  <div class="out-080411">
    <div class="banner"></div>
    <list-news-01></list-news-01>
  </div>
</template>

<script>
import ListNews01 from './webComponents/List-news-01.vue'

export default {
  components: { ListNews01 }
}
</script>

<style scoped lang="less">
.out-080411 {
  width: 100%;
  min-width: 1200px;
  .banner {
    width: 100%;
    background: url("../assets/images/n_banner.jpg") no-repeat center;
    height: 400px;
  }
}
</style>
