<template>
  <div class="out-080310">
    <div class="inside_banner">
    </div>
    <div class="services-title">
      <img src="../assets/images/s01.png"
           alt="">
    </div>
    <list-cards-03></list-cards-03>
    <div class="middle clearfix">
      <div class="title">
        <img src="../assets/images/s14.jpg"
             alt="">
      </div>
      <div class="wrap">
        <img src="../assets/images/s15.jpg"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
import ListCards03 from './webComponents/List-cards-03.vue'

export default {
  components: { ListCards03 }
}
</script>

<style scoped lang="less">
.out-080310 {
  width: 100%;
  min-width: 1200px;
  .inside_banner {
    width: 100%;
    height: 400px;
    background: url("../assets/images/service_banner.jpg") no-repeat center;
  }
  .services-title {
    margin: 30px auto;
  }
  .middle {
    margin-top: 50px;
    width: 100%;
    .title {
      margin: 30px auto;
    }
    .wrap {
      margin: 0 auto;
      width: 1200px;
      height: 650px;
    }
  }
}
</style>
