<template>
  <div class="out-080319">
    <div class="banner"></div>
    <div class="mobile_column">

      <div class="home_title">
        <img src="../assets/images/m02.jpg"
             width="187"
             height="56"
             alt="手机网站建设">
      </div>
      <ul class="clearfix">
        <li>
          <img src="../assets/images/m03.jpg"
               alt="手机网站建设"
               width="180"
               height="208">
          <p class="title">
            定制设计版面
            <br>
            让你手机网站与众不同
          </p>
          <p class="intro">
            资深设计师与你一对一沟通，根据你企业文化与行业特点，创意设计出风格独一无二的手机网站
          </p>
        </li>
        <li>
          <img src="../assets/images/m04.jpg"
               alt="微信网站建设"
               width="180"
               height="208">
          <p class="title">
            PC网站和手机网站共享数据
            <br>
            同步更新，管理方便
          </p>
          <p class="intro">
            手机端和PC端共用数据库PC网站后台统一管理，操作方便
          </p>
        </li>
        <li>
          <img src="../assets/images/m05.jpg"
               alt="移动网站建设"
               width="180"
               height="208">
          <p class="title">
            网站打开速度快
            <br>
            用最少的代码完成所需功能
          </p>
          <p class="intro">
            我们不是吝啬我们的代码而是为了追求最完美的作品
          </p>
        </li>
      </ul>
    </div>
    <div class="wechat">
      <div class="home_title">
        <img src="../assets/images/m07.png"
             alt="制作微信网站">
      </div>
      <div class="posr"
           style="overflow:hidden;">
        <dl>
          <dt>
            轻松打造微信网站
          </dt>
          <dd>
            通过微信公众号或服务号与手机网站进行对接，
          </dd>
          <dd>
            轻松打造和手机网站一样的微信网站，
          </dd>
          <dd>
            数据和手机网站同步，轻松实现微信营销。
          </dd>
        </dl>
        <div class="wechat_img">
          <img src="../assets/images/m061.png"
               alt="微信网站">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="less">
.out-080319 {
  width: 100%;
  min-width: 1200px;
  .banner {
    width: 100%;
    height: 400px;
    background: url("../assets/images/m_banner.jpg") no-repeat center;
  }
  .mobile_column {
    width: 1200px;
    margin: 30px auto;
    .home_title {
      margin: 20px auto;
    }
    ul {
      width: 1200px;
      margin: 0 auto;
      li {
        float: left;
        width: 370px;
        margin: 15px;
        img {
          margin: 0 auto;
        }
        .title {
          text-align: center;
          font-size: 16px;
          line-height: 20px;
          font-family: "微软雅黑";
          color: black;
          margin: 20px auto;
        }
        .intro {
          text-align: center;
          font-size: 14px;
          line-height: 18px;
          color: #888;
          margin: 10px auto;
          width: 80%;
        }
      }
    }
  }
  .wechat {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 700px;
    background: url(../assets/images/m06.jpg) no-repeat center top;
    .home_title {
      margin: 30px auto;
      img {
        width: 220px;
        height: 56px;
      }
    }
    .posr {
      width: 600px;
      dl {
        width: 350px;
        margin: 100px 130px;
        dt {
          text-align: center;
          line-height: 50px;
          font-size: 28px;
          font-family: "微软雅黑";
          margin-bottom: 30px;
        }
        dd {
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          font-family: "微软雅黑";
        }
      }
    }
    .wechat_img {
      position: absolute;
      top: 70px;
      right: 70px;
      width: 540px;
      height: 640px;
    }
  }
}
</style>
