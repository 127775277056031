<template>
  <div class="serve_column">
    <ul class="fix">
      <li class="c1">
        <dl>
          <dt class="pic">
            <a>
              <img src="../../assets/images/service_1.jpg"
                   alt="品牌官网设计"
                   width="270"
                   height="200" />
            </a>
          </dt>
          <dt class="t">
            <a>
              品牌官网设计
            </a>
          </dt>
          <dd>
            策划、设计、前端、程序开发全方位一条龙服务
          </dd>
          <dd>
            充分了解企业文化、行业特点，体现企业特色
          </dd>
          <dd>
            设计师一对一沟通，充分了解你的需求和想法
          </dd>
          <dd>
            时刻掌握最新技术动向，打造高品质网站
          </dd>
        </dl>
      </li>
      <li class="c2">
        <dl>
          <dt class="pic">
            <a>
              <img src="../../assets/images/service_2.jpg"
                   alt="商城网站开发"
                   width="270"
                   height="200" />
            </a>
          </dt>
          <dt class="t">
            <a>
              商城网站开发
            </a>
          </dt>
          <dd>
            专业量身定制设计、定制功能、定制后台开发
          </dd>
          <dd>
            拥有资深开发团队，追求质量同时保证速度
          </dd>
          <dd>
            追求完美，不断提升系统响应速度和稳定性
          </dd>
          <dd>
            完善的测试流程，在上线之前解决问题
          </dd>
        </dl>
      </li>
      <li class="c3">
        <dl>
          <dt class="pic">
            <a>
              <img src="../../assets/images/service_3.jpg"
                   alt="手机微信网站建设"
                   width="270"
                   height="200" />
            </a>
          </dt>
          <dt class="t">
            <a>
              手机、微信网站建设
            </a>
          </dt>
          <dd>
            个性设计，独一无二，用设计风格体现企业文化
          </dd>
          <dd>
            精简代码、追求速度，兼容主流手机浏览器
          </dd>
          <dd>
            和PC端数据同步，管理方便，全方位网络营销
          </dd>
          <dd>
            零成本增加微信网站
          </dd>
        </dl>
      </li>
      <li class="c4">
        <dl>
          <dt class="pic">
            <a>
              <img src="../../assets/images/service_4.jpg"
                   alt="解决方案"
                   width="270"
                   height="200" />
            </a>
          </dt>
          <dt class="t">
            <a>
              高端网站定制
            </a>
          </dt>
          <dd>
            只要逻辑可行，我们就能帮你实现
          </dd>
          <dd>
            完全根据你的想法，创新策划解决方案
          </dd>
          <dd>
            采用最新网站开发技术,全方位考虑各种因素
          </dd>
          <dd>
            用最短的时间定制出最好的网站
          </dd>
        </dl>
      </li>
    </ul>
    <div class="c">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.serve_column {
  margin: 30px auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  min-width: 1200px;
  ul {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    list-style-type: none;
    li {
      width: 270px;
      flex-wrap: wrap;
      width: 45%;
      height: 390px;
      background: #ffffff;

      dt.pic {
        border: 1px solid #fff;
        width: 270px;
        height: 200px;
        overflow: hidden;
        position: relative;
        img {
          transition: all 1s;
          position: absolute;
          left: 0px;
          top: 0px;
        }
        &:hover img {
          transform: scale(1.2);
        }
      }
      dt.t {
        height: 66px;
        line-height: 66px;
        text-align: left;
        a {
          font-size: 18px;
          color: #000000;
          font-weight: normal;
        }
      }
    }
    dd {
      line-height: 24px;
      text-align: left;
      color: #888888;
      display: list-item;
      white-space: nowrap;
      font-size: 12px;
      font-family: "宋体";
    }
  }
}

.serve_column li dt.t a:hover {
  color: #15909c;
}

.service_foot {
  height: 80px;
  text-align: center;
  background-color: #60d0db;
  color: #fff;
  font-size: 20px;
  line-height: 80px;
}
a:link {
  color: #666666;
  text-decoration: none;
}
a:visited {
  color: #666666;
  text-decoration: none;
}
a:hover {
  color: #0093ff;
  text-decoration: none;
}
a:active {
  color: #0093ff;
  text-decoration: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ul,
ol,
li {
  list-style-type: none;
}
</style>
