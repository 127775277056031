<template>
  <div class="out-073181">
    {{text1}}
    <br />
    {{text2}}
  </div>
</template>

<script>
export default {
  props: ['text1', 'text2']
}
</script>

<style scoped lang="less">
.out-073181 {
  color: #222222;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  margin: 50px auto;
}
</style>
