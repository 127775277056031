<template>
  <div class="out-080201">
    <table>
      <tr v-for="(item,index) in list"
          :key="index">
        <td v-for="(item2,index2) in item"
            :key="index2">
          <div class="pic partner_box">
            <div class="cont1">
              <img :src="item2.src01"
                   width="150"
                   height="100"
                   alt="环球石材" />
            </div>
            <div class="cont2">
              <img :src="item2.src02"
                   width="150"
                   height="100"
                   alt="环球石材" />
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import img0101 from '../../assets/images/569cc06f1c4a1.jpg'
import img0102 from '../../assets/images/569ca62ca6eb1.jpg'
import img0201 from '../../assets/images/569cc0801b119.jpg'
import img0202 from '../../assets/images/569ca5104a946.jpg'
import img0301 from '../../assets/images/569cc08aa258c.jpg'
import img0302 from '../../assets/images/569ca41a7a263.jpg'
import img0401 from '../../assets/images/56ea529438233.jpg'
import img0402 from '../../assets/images/56ea529029b70.jpg'

export default {
  data () {
    return {
      list: [
        [
          { src01: img0101, src02: img0102 },
          { src01: img0201, src02: img0202 },
          { src01: img0301, src02: img0302 },
          { src01: img0401, src02: img0402 },
          { src01: img0101, src02: img0102 }
        ],
        [
          { src01: img0101, src02: img0102 },
          { src01: img0201, src02: img0202 },
          { src01: img0301, src02: img0302 },
          { src01: img0401, src02: img0402 },
          { src01: img0101, src02: img0102 }
        ]
      ]
    }
  }
}
</script>

<style scoped lang="less">
.out-080201 {
  width: 1200px;
  margin: 40px auto;
  padding-bottom: 20px;
  table {
    border-collapse: collapse;
    border: 1px solid #e6e6e6;
    td {
      width: 238px;
      height: 138px;
      border: 1px solid #e6e6e6;
      .partner_box {
        width: 150px;
        height: 100px;
        padding: 19px 44px;
        position: relative;
        z-index: 1;
        .cont1 {
          width: 150px;
          height: 100px;
          position: absolute;
          top: 19px;
          left: 44px;
          z-index: 2;
          filter: alpha(opacity=60);
          opacity: 0.6;
          transition: all 0.5s linear 10ms;
        }
        .cont2 {
          width: 150px;
          height: 100px;
          filter: alpha(opacity=0);
          opacity: 0;
          position: absolute;
          top: 19px;
          left: 44px;
          z-index: 1;
          transform: rotateY(180deg);
          transition: all 0.5s linear 10ms;
        }
        &:hover {
          .cont1 {
            transform: rotateY(180deg);
            z-index: 1;
            opacity: 0;
          }
          .cont2 {
            transform: rotateY(360deg);
            z-index: 2;
            opacity: 1;
          }
        }
      }
      .partner_box2:hover {
        border: 8px solid #f6f6f6;
        padding: 11px 36px;
      }
    }
  }
}
</style>
