<template>
  <div class="out-073005">
    <img-text-call></img-text-call>
    <img-text-email></img-text-email>
    <img-text-ewm></img-text-ewm>
  </div>

</template>

<script>
import ImgTextCall from './Img-text-call.vue'
import ImgTextEmail from './Img-text-email.vue'
import ImgTextEwm from './Img-text-ewm.vue'
export default {
  components: { ImgTextCall, ImgTextEmail, ImgTextEwm }
}
</script>

<style scoped lang="less">
.out-073005 {
  display: flex;
  justify-content: flex-end;
  color: #888888;
  padding-top: 12px;
}
</style>
