<template>
  <div class="footer">
    <footer-01></footer-01>
  </div>
</template>

<script>
import Footer01 from './webComponents/Footer-01.vue'
export default {
  components: { Footer01 }
}
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  min-width: 1200px;
}
</style>
