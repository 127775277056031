<template>
  <div class="out-073160">
    <span class="text">从PC到移动互联网IT联盟打造一个全方位的互联网营销体系</span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073160 {
  width: 100%;
  height: 30px;
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 12px;
    font-family: "宋体", "微软雅黑";
  }
}
</style>
