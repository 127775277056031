<template>
  <div class="out-073150">
    <div class="left"></div>
    <div class="center"></div>
    <div class="right"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073150 {
  margin: 30px auto;
  width: 1200px;
  height: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    width: 300px;
    height: 1px;
    background-color: #ccc;
  }
  .center {
    width: 12px;
    height: 12px;
    border-radius: 50% 50%;
    background-color: rgb(14, 175, 187);
    margin: 0 5px;
  }
  .right {
    width: 300px;
    height: 1px;
    background-color: #ccc;
  }
}
</style>
