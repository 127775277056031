<template>
  <xfz-header></xfz-header>
  <xfz-about-body></xfz-about-body>
  <xfz-footer></xfz-footer>
</template>

<script>
import XfzAboutBody from '../components/Xfz-about-body.vue'
import XfzFooter from '../components/Xfz-footer.vue'
import XfzHeader from '../components/Xfz-header.vue'
export default {
  components: { XfzHeader, XfzFooter, XfzAboutBody }
}
</script>

<style>
</style>
