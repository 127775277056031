<template>
  <div class="btn-080110 btn_cases_more">
    <a title="成功案例"
       class="btn"
       @click="$router.push('/cases')">
      查看更多
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.btn-080110 {
  border: 1px solid #15909c;
  margin: 50px auto;
  text-align: center;
  width: 120px;
  height: 36px;
  a {
    line-height: 36px;
    color: #15909c;
    font-size: 12px;
    font-family: "宋体";
    width: 120px;
    height: 36px;
    display: block;
    transition: all 0.5s;
    text-decoration: none;
  }
  &:hover a {
    background-color: #15909c;
    color: #fff;
  }
}
</style>
