<template>
  <div class="out-073004">
    <a class="ewm">
      <span class="weixin">
      </span>
      噫吖微信
      <img src="../../assets/logo.png"
           width="158"
           height="158"
           alt="TI微信" />
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073004 {
  display: inline-block;
  margin-left: 8px;
  .ewm {
    position: relative;
    z-index: 10000;
    font-size: 12px;
    font-family: "微软雅黑", Arial;
    text-align: right;
    color: #888888;
    .weixin {
      display: inline-block;
      width: 18px;
      height: 16px;
      background: url("../../assets/images/sprite.png") no-repeat -60px 0;
      vertical-align: bottom;
      margin-right: 4px;
    }
    img {
      position: absolute;
      right: 0;
      top: 30px;
      z-index: 999;
      border: 1px solid #ddd;
      padding: 4px;
      background: #fff;
      display: none;
      z-index: 10000;
    }
    // &:hover img {
    //   display: block;
    // }
  }
}
</style>
