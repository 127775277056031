<template>
  <div class="out-073001">
    <nav class="nav">
      <ul class="fix"
          @mouseleave="xfzleave">
        <li id="menu1"
            @mouseenter="xfzenter">
          <a @click="jump('/index')"
             href="#">
            官网首页
          </a>
        </li>
        <li id="menu2"
            @mouseenter="xfzenter"
            @mouseleave="isShow = !isShow">
          <a @click.prevent="jump('/about')"
             href="#">
            关于我们
          </a>
        </li>
        <li id="menu3"
            @mouseenter="xfzenter">
          <a @click.prevent="jump('/cases')"
             href="#">
            成功案例
          </a>
        </li>
        <li id="menu4"
            @mouseenter="xfzenter">
          <a @click.prevent="jump('/services')"
             href="#">
            服务范围
          </a>
        </li>
        <li id="menu5"
            @mouseenter="xfzenter">
          <a @click.prevent="jump('/mobile')"
             href="#">
            移动终端
          </a>
        </li>
        <li id="menu6"
            @mouseenter="xfzenter">
          <a @click.prevent="jump('/solution')"
             href="#">
            解决方案
          </a>
        </li>
        <li id="menu7"
            @mouseenter="xfzenter">
          <a @click.prevent="jump('/news')"
             href="#">
            新闻资讯
          </a>
        </li>
        <li id="menu8"
            @mouseenter="xfzenter">
          <a @click.prevent="jump('/contact')"
             href="#">
            联系我们
          </a>
        </li>
      </ul>
      <div class="nav_icon"
           ref="line">
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShow: false,
      left: 0
    }
  },
  methods: {
    xfzenter (event) {
      // 得到触发事件的元素的左偏移
      // console.log(event.target.offsetLeft)
      // 得到ref引用的的元素
      // console.log(this.$refs.line)
      this.$refs.line.style.left = event.target.offsetLeft + 40 + 'px'
    },
    xfzleave (event) {
      this.$refs.line.style.left = '40px'
    },
    jump (path) {
      this.$router.push(path)
    }
  }

}
</script>

<style scoped lang="less">
.out-073001 {
  float: right;
  .nav {
    margin-top: 18px;
    position: relative;
    height: 40px;
    > ul {
      padding-left: 0px;
      li {
        float: left;
        padding-left: 40px;
        height: 24px;
        list-style-type: none;
        position: relative;
        a {
          font-size: 14px;
          color: #000000;
          line-height: 24px;
          display: inline-block;
          text-decoration: none;
        }
      }
    }
    .fix:after {
      clear: both;
      content: "";
      display: table;
    }
    .nav_icon {
      background: #15909c;
      height: 1px;
      width: 56px;
      line-height: 1px;
      // display: inline-block;
      position: relative;
      margin-top: 12px;
      top: -6px;
      left: 40px;
      transition: all 0.4s;
    }
  }
}
</style>
