<template>
  <div class="out-073002">
    <span class="call">
    </span>
    <span class="call_tel">13510798915</span>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073002 {
  display: inline-block;
  margin-left: 8px;
  .call {
    background: url("../../assets/images/sprite.png") no-repeat 0 0;
    display: inline-block;
    width: 20px;
    height: 16px;
    vertical-align: bottom;
    margin-right: 4px;
  }
  .call_tel {
    color: #15909c;
    font-size: 12px;
    font-family: "微软雅黑", Arial;
  }
}
</style>
