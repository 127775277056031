<template>
  <div class="out-080316">
    <ul class="clearfix">
      <li>
        <img src="../../assets/images/s02.jpg"
             alt="原型图制作"
             class="">
        <div class="blur_shade">
          <h4>
            原型图策划
          </h4>
          <p class="text">
            无原型、不设计
            <br>
            大大减少制作误差，为客户提供全方位策划服务
          </p>
        </div>
      </li>
      <li>
        <img src="../../assets/images/s03.jpg"
             alt="网站设计"
             class="">
        <div class="blur_shade">
          <h4>
            创意设计
          </h4>
          <p class="text">
            设计无价、创意无价
            <br>
            独具慧眼的设计风格，提升企业形象
          </p>
        </div>
      </li>
      <li>
        <img src="../../assets/images/html5css3.jpg"
             alt="前端制作"
             class="">
        <div class="blur_shade">
          <h4>
            前端制作
          </h4>
          <p class="text">
            用网页展示设计图
            <br>
            绝对的逼真，良好的兼容，全平台展示
          </p>
        </div>
      </li>
      <li>
        <img src="../../assets/images/s05.jpg"
             alt="程序开发"
             class="">
        <div class="blur_shade">
          <h4>
            程序开发
          </h4>
          <p class="text">
            让代码帮你做事
            <br>
            便捷的管理高效的执行，稳定的网站
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-080316 {
  width: 100%;
  ul {
    width: 1200px;
    margin: 20px auto;
    li {
      float: left;
      position: relative;
      height: 270px;
      width: 270px;
      margin: 0 15px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        transition: all 0.5s;
      }
      .blur_shade {
        text-align: center;
        height: 600px;
        position: absolute;
        top: -600px;
        left: 0px;
        opacity: 0;
        transition: all 0.5s;
        background: url("../../assets/images/blur.png") no-repeat;
        h4 {
          font-size: 20px;
          font-family: "微软雅黑";
          color: black;
          margin: 80px 0px 30px 0px;
        }
        .text {
          padding: 0 20px;
          font: 14px "宋体";
          color: rgb(7, 7, 7);
          line-height: 20px;
        }
      }
      &:hover {
        img {
          filter: blur(4px);
        }
        .blur_shade {
          top: 0px;
          opacity: 1;
        }
      }
    }
  }
}
</style>
