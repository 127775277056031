<template>
  <div class="out-080305">
    <div class="wrap">
      <span class="title">CASE</span>
      <div class="line">
        <div class="left"></div>
        <div class="center">案例</div>
        <div class="right"></div>
      </div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-080305 {
  width: 100%;
  .wrap {
    text-align: center;
    width: 1200px;
    margin: 30px auto;
    .title {
      color: rgb(5, 121, 167);
      font-size: 45px;
    }
    .line {
      display: flex;
      width: 1200px;
      height: 5px;
      margin: 30px 0px;
      justify-content: center;
      .left {
        height: 1px;
        background-color: #aaa;
        width: 300px;
      }
      .center {
        text-align: center;
        font-size: 25px;
        margin: -15px 15px;
        border-bottom: 1px rgb(7, 144, 168) solid;
        padding-bottom: 3px;
      }
      .right {
        height: 1px;
        background-color: #aaa;
        width: 300px;
      }
    }
  }
}
</style>
