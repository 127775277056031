<template>
  <div class="out-080182">
    <ul class="portfolio-grid-080101 clearfix">
      <!-- Thumbnail -->
      <li class="thumbnail clearfix"
          v-for="item in list"
          :key="item.id">
        <a class="thumbnail_a">
          <img :src="item.src"
               class="cases_img"
               :alt="item.title" />
          <div class="projectinfo">
          </div>
        </a>
        <div class="meta clearfix">
          <h4 class="fl">
            <a>
              {{item.title}}
            </a>
          </h4>
          <div class="fr">
            <a class="cases_a_pc">
            </a>
            <a class="cases_a_phone">
            </a>
          </div>
          <div class="c">
          </div>
        </div>
      </li>
    </ul>
  </div>

</template>

<script>
import img01 from '../../assets/images/56976178f3378.jpg'
import img02 from '../../assets/images/569761948fee5.jpg'
import img03 from '../../assets/images/569761b2ac690.jpg'
import img04 from '../../assets/images/569761a500f52.jpg'
import img05 from '../../assets/images/569761bf5dd7f.jpg'
import img06 from '../../assets/images/569761d57b071.jpg'
import img07 from '../../assets/images/569761e2d440a.jpg'
import img08 from '../../assets/images/56dffce7929f5.jpg'
import img09 from '../../assets/images/555ad7d7b3f55.jpg'
import img010 from '../../assets/images/1430363427.jpg'
import img011 from '../../assets/images/55532e8b0d698.jpg'
import img012 from '../../assets/images/55cab873042a9.jpg'
import img013 from '../../assets/images/555c4cc41e330.jpg'
import img014 from '../../assets/images/56261c2fe31dd.jpg'
import img015 from '../../assets/images/5673e73118f94.png'
import img016 from '../../assets/images/1430380990.jpg'

export default {
  data () {
    return {
      list: [
        {
          src: img01,
          title: '澳邦乳业-澳德华园'
        },
        {
          src: img02,
          title: '深圳机场地产'
        }, {
          src: img03,
          title: '欧杰酒业-商城'
        },
        {
          src: img04,
          title: '美联英语'
        }, {
          src: img05,
          title: '乐普医疗'
        },
        {
          src: img06,
          title: '我爱海淘'
        }, {
          src: img07,
          title: '宝石汇商城'
        },
        {
          src: img08,
          title: '小牛资本'
        },
        {
          src: img09,
          title: '东海航空'
        },
        {
          src: img010,
          title: '磨房旅游网站'
        },
        {
          src: img011,
          title: '美联英语'
        },
        {
          src: img012,
          title: '传奇数码'
        },
        {
          src: img013,
          title: '大族元亨光电股份'
        },
        {
          src: img014,
          title: '顺天运环保科-立冰电器'
        },
        {
          src: img015,
          title: '浙江鹏孚隆科技有限公司'
        },
        {
          src: img016,
          title: '新居网'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.out-080182 {
  width: 100%;
  line-height: 0px;
  margin-top: 50px;
  .portfolio-grid-080101 {
    width: 100%;
    min-width: 1200px;
    margin-top: 20px;
    .thumbnail {
      overflow: hidden;
      position: relative;
      float: left;
      width: 24%;
      margin: 0.5%;
      a.thumbnail_a {
        display: inline-block;
        position: relative;
        // overflow: hidden;
        padding: 0px;
        text-decoration: none;
        img {
          width: 100%;
          height: 100%;
        }
        div {
          display: none;
          width: 100%;
          height: 100%;
          background-color: rgba(126, 127, 115, 0.4);
          position: absolute;
          z-index: 999;
          left: 0px;
          top: 0px;
        }
        &:hover div {
          display: block;
        }
        &:hover {
          cursor: url("../../assets/images/index201601/cases_shi.png") 40 40,
            crosshair;
        }
      }
      .meta {
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: left;
        overflow: hidden;
        h4 {
          font-size: 18px;
          padding-left: 2%;
          font-weight: normal;
          width: 60%;
          float: left;
          a {
            color: #111111;
            text-decoration: none;
          }
        }
        .fr {
          float: right;
          width: 38%;
          text-align: right;
          a {
            display: inline-block;
            padding: 0px 2px;
            padding-right: 12px;
            width: 20px;
            height: 60px;
          }
          .cases_a_pc {
            background: url("../../assets/images/index201601/cases_ico_pc_b.png")
              no-repeat center;
          }
          .cases_a_phone {
            background: url("../../assets/images/index201601/cases_ico_phone_b.png")
              no-repeat center;
          }
        }
      }
      &:hover .meta {
        background-color: #2eafbb;
        h4 a {
          color: #fff;
        }
        .cases_a_pc {
          background: url("../../assets/images/index201601/cases_ico_pc_w.png")
            no-repeat center;
        }
        .cases_a_phone {
          background: url("../../assets/images/index201601/cases_ico_phone_w.png")
            no-repeat center;
        }
      }
    }
  }
}
</style>
