<template>
  <div class="out-080401">
    <div class="banner"></div>
    <list-cards-04></list-cards-04>
  </div>
</template>

<script>
import ListCards04 from './webComponents/List-cards-04.vue'

export default {
  components: { ListCards04 }
}
</script>

<style scoped lang="less">
.out-080401 {
  width: 100%;
  min-width: 1200px;
  .banner {
    width: 100%;
    background: url("../assets/images/sl_banner.jpg") no-repeat center;
    height: 400px;
  }
}
</style>
