import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Xfzindex from '../views/Xfzindex.vue'
import Xfzabout from '../views/Xfzabout.vue'
import Xfznews from '../views/Xfznews.vue'
import Xfzcases from '../views/Xfzcases.vue'
import Xfzservices from '../views/Xfzservices.vue'
import Xfzsolution from '../views/Xfzsolution.vue'
import Xfzcontact from '../views/Xfzcontact.vue'
import Xfzmobile from '../views/Xfzmobile.vue'

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: Xfzindex
  },
  {
    path: '/about',
    component: Xfzabout
  },
  {
    path: '/news',
    component: Xfznews
  },
  {
    path: '/cases',
    component: Xfzcases
  },
  {
    path: '/services',
    component: Xfzservices
  },
  {
    path: '/solution',
    component: Xfzsolution
  },
  {
    path: '/contact',
    component: Xfzcontact
  },
  {
    path: '/mobile',
    component: Xfzmobile
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
