<template>
  <div class="out-080462">
    <title-03></title-03>
    <grid-02></grid-02>
  </div>

</template>

<script>
import Grid02 from './webComponents/Grid-02.vue'
import Title03 from './webComponents/Title-03.vue'
export default {
  components: { Title03, Grid02 }
}
</script>

<style scoped lang="less">
.out-080462 {
  width: 100%;
  min-width: 1200px;
}
</style>
