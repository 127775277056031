<template>
  <div class="out-073165">
    站在用户的角度思考问题，与客户深入沟通，找到网站设计与推广的最佳解决方案
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.out-073165 {
  width: 100%;
  min-width: 1200px;
  height: 80px;
  text-align: center;
  background-color: #60d0db;
  color: #fff;
  font-size: 20px;
  line-height: 80px;
}
</style>
