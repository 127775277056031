<template>
  <div class="out-080461">
    <title-02></title-02>
    <line-01></line-01>
    <text-line-center-04 :text1="text1"
                         :text2="text2"></text-line-center-04>
    <company-culture-01></company-culture-01>
  </div>

</template>

<script>
import CompanyCulture01 from './webComponents/Company-culture-01.vue'
import Line01 from './webComponents/Line-01.vue'
import TextLineCenter04 from './webComponents/Text-line-center-04.vue'
import Title02 from './webComponents/Title-02.vue'
export default {
  components: {
    Title02,
    TextLineCenter04,
    CompanyCulture01,
    Line01
  },
  data () {
    return {
      text1: '励志为顾客提供出最好的网站建设、管理、维护系统等。本团队为此收录了大量的技术和相关人员。',
      text2: '本站积极鼓励客户前来本站交流互动，积极鼓励为本站提出创新的好点子。本团队专注于企业的网站设计制作，为刚接触网站开发和运行的客户提供最能帮助到你的解决方案。'
    }
  }
}
</script>

<style scoped lang="less">
.out-080461 {
  width: 100%;
  min-width: 1200px;
}
</style>
