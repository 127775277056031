<template>
  <xfz-header></xfz-header>
  <xfz-news-body></xfz-news-body>
  <xfz-footer></xfz-footer>
</template>

<script>
import XfzFooter from '../components/Xfz-footer.vue'
import XfzHeader from '../components/Xfz-header.vue'
import XfzNewsBody from '../components/Xfz-news-body.vue'
export default {
  components: { XfzHeader, XfzFooter, XfzNewsBody }
}
</script>

<style>
</style>
